import React from 'react';
import { graphql, PageProps } from 'gatsby';
import styled from 'styled-components';

// components
import {
  Layout,
  Seo,
  Section,
  Flow,
  PostCard,
  PageContent,
  StreamEmbed,
  SubTitle,
  Button,
} from '@/components';

// styled components
const SeeMoreWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 3rem;
`;

/**
 * The site's Home page
 */
const HomePage = ({ data }: PageProps<Queries.HomePageQuery>) => {
  const recentPosts = data.allWpPost.nodes;
  const livestream = data.wpPage?.livestream;
  const content = recentPosts.map((node) =>
    node ? (
      <Section key={node.id}>
        <PostCard post={node} />
      </Section>
    ) : null
  );

  return (
    <Layout withLanding withSidebar>
      <Seo title="Bringing Glory" pathname="/" />
      <Flow spacing={12}>
        {livestream?.currentlyStreaming && livestream?.livestreamUrl ? (
          <Section>
            <PageContent>
              <SubTitle>We're live streaming!</SubTitle>
              <StreamEmbed stream={livestream?.livestreamUrl} />
            </PageContent>
          </Section>
        ) : null}
        {content}
        <SeeMoreWrap>
          <Button to="/archive/">See more posts</Button>
        </SeeMoreWrap>
      </Flow>
    </Layout>
  );
};

export default HomePage;

// page query
export const query = graphql`
  query HomePage {
    allWpPost(sort: { fields: date, order: DESC }, limit: 3) {
      nodes {
        title
        uri
        date
        excerpt
        id
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    wpPage(uri: { eq: "/livestream/" }) {
      livestream {
        currentlyStreaming
        livestreamUrl
      }
    }
  }
`;
